import React, {PureComponent} from 'react';
import Dropdown from "../UI/Dropdown";
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Formik} from 'formik';
import {IDictionary} from "../../models/Dictionary";
import {getDictionary, getDictionaryOptions} from "../../utils/dictionaries";
import _ from 'lodash';
import {IOrderArchiveRequest} from "../../models/Order";
import DateTimePicker from "../UI/DateTimePicker";
import {dateConvert} from "../../utils/date-convert";
import {ExternalDeliveryServices} from "../../utils/order";


type ArchiveOrderFilterType = {
    filter?: IOrderArchiveRequest
    setFilterHandler: (form: IOrderArchiveRequest) => void
    dictionaryCityEagers: IDictionary[]
    dictionaryOrderStatuses: IDictionary[]
}


class ArchiveOrderFilter extends PureComponent<ArchiveOrderFilterType> {
    render() {
        const {filter, dictionaryCityEagers, dictionaryOrderStatuses, setFilterHandler} = this.props;

        const resetValues = {
            id: '',
            externalId: '',
            dateStart: null,
            dateEnd: null,
            cityId: null,
            companyId: null,
            pointId: null,
            status: null,
            courierId: null,
            paymentTypeId: null,
            companyName: '',
            hasReturnCheck: null,
            isExternalCourier: null,
            deliveryService: null,
        };

        const initialValues = {...resetValues, ...filter,
            dateStart: filter?.dateStart &&
                dateConvert(filter.dateStart, 'yyyy-MM-dd', 'dd-MM-yyyy'),
            dateEnd: filter?.dateEnd &&
                dateConvert(filter.dateEnd, 'yyyy-MM-dd', 'dd-MM-yyyy'),
        };

        const cityOptions = getDictionaryOptions(dictionaryCityEagers);
        const statusOptions = getDictionaryOptions(dictionaryOrderStatuses.filter(({key}) => key > 99 || key < 1));

        return (
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values) => {
                    const {dateStart, dateEnd} = values;

                    const filter = {
                        ...values,
                        dateStart: dateStart && dateConvert(dateStart, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                        dateEnd: dateEnd && dateConvert(dateEnd, 'dd-MM-yyyy', 'yyyy-MM-dd'),
                    }

                    const form = _.pickBy(filter, function (value) {
                        return !(value === undefined || value === null || value === '');
                    });

                    setFilterHandler(form)
                }}
            >
                {({setFieldValue, values, handleChange, dirty, handleSubmit, resetForm}) => {
                    const cityId = values.cityId;

                    const city = cityId !== null ? getDictionary(dictionaryCityEagers, cityId as number) : undefined;

                    const points = _.get(city, 'points', []) as { [key: string]: string }[];
                    const pointOptions = getDictionaryOptions(points);

                    const companies = _.get(city, 'companies', []) as { [key: string]: string }[];
                    const companyOptions = getDictionaryOptions(companies);

                    const couriers = _.get(city, 'couriers', []) as { [key: string]: string }[];
                    const courierOptions = getDictionaryOptions(couriers);

                    const isReset = _.isEmpty(_.pickBy(initialValues, function (value) {
                        return !(value === undefined || value === null || value === '');
                    }));

                    const resetFormHandler = () => {
                        resetForm(resetValues);
                        setFilterHandler({})
                    };


                    return (
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Номер заказа</Label>
                                        <Input
                                            type="text"
                                            name="id"
                                            value={values.id}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Внешний номер заказа</Label>
                                        <Input
                                            type="text"
                                            name="externalId"
                                            value={values.externalId}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Город</Label>
                                        <Dropdown
                                            name={'cityId'}
                                            value={values.cityId}
                                            options={cityOptions}
                                            onChange={(value) => {
                                                setFieldValue('pointId', null);
                                                setFieldValue('companyId', null);
                                                setFieldValue('courierId', null);
                                                setFieldValue('cityId', value);
                                            }}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Контрагент</Label>
                                        <Dropdown
                                            name={'companyId'}
                                            value={values.companyId}
                                            isDisabled={values.cityId === null}
                                            options={companyOptions}
                                            onChange={(value) => setFieldValue('companyId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label>Период поступления заказа</Label>
                                        <Row form>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateStart`}
                                                    /*value={_.get(statistics, `dateStart`)}
                                                    onChange={(dateStart) => dateStart !== null && this.changeStatisticHandle({
                                                        ...statistics,
                                                        dateStart
                                                    })}*/
                                                    placeholder={'с'}
                                                    value={values.dateStart}
                                                    onChange={(value) => {
                                                        setFieldValue('dateStart', value)
                                                    }}
                                                />
                                            </Col>
                                            <Col>
                                                <DateTimePicker
                                                    name={`dateEnd`}
                                                    placeholder={'по'}
                                                    /*value={_.get(statistics, `dateEnd`)}
                                                    onChange={(dateEnd) => dateEnd !== null && this.changeStatisticHandle({
                                                        ...statistics,
                                                        dateEnd
                                                    })}*/
                                                    value={values.dateEnd}
                                                    onChange={(value) => {
                                                        setFieldValue('dateEnd', value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Col>

                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Район</Label>
                                        <Dropdown
                                            name={'pointId'}
                                            value={values.pointId}
                                            isDisabled={values.cityId === null}
                                            isSearchable={true}
                                            options={pointOptions}
                                            onChange={(value) => setFieldValue('pointId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Статус заказа</Label>
                                        <Dropdown
                                            name={'status'}
                                            value={values.status}
                                            options={statusOptions}
                                            onChange={(value) => setFieldValue('status', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Курьер</Label>
                                        <Dropdown
                                            name={'courierId'}
                                            isDisabled={values.cityId === null}
                                            value={values.courierId}
                                            options={courierOptions}
                                            onChange={(value) => setFieldValue('courierId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Тип оплаты</Label>
                                        <Dropdown
                                            name={'paymentTypeId'}
                                            value={values.paymentTypeId}
                                            options={[
                                                {
                                                    label: "Наличными",
                                                    value: 1
                                                },
                                                {
                                                    label: "POS терминал",
                                                    value: 2
                                                },
                                                {
                                                    label: "Оплаченный заказ",
                                                    value: 3
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('paymentTypeId', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Название заведения</Label>
                                        <Input
                                            type="text"
                                            name="companyName"
                                            value={values.companyName}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Наличие возвратного чека</Label>
                                        <Dropdown
                                            name={'hasReturnCheck'}
                                            value={values.hasReturnCheck}
                                            options={[
                                                {
                                                    label: "Есть",
                                                    value: true
                                                },
                                                {
                                                    label: "Отсутствует",
                                                    value: false
                                                }
                                            ]}
                                            onChange={(value) => setFieldValue('hasReturnCheck', value)}
                                            isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Внешняя доставка</Label>
                                        <Dropdown
                                          name={'isExternalCourier'}
                                          value={values.isExternalCourier}
                                          options={[
                                              {
                                                  label: "Да",
                                                  value: true
                                              },
                                              {
                                                  label: "Нет",
                                                  value: false
                                              }
                                          ]}
                                          onChange={(value) => setFieldValue('isExternalCourier', value)}
                                          isClearable
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={'3'}>
                                    <FormGroup>
                                        <Label>Сервис доставки</Label>
                                        <Dropdown
                                          name={'deliveryService'}
                                          value={values.deliveryService}
                                          options={[
                                              {
                                                  label: "Yandex",
                                                  value: ExternalDeliveryServices.Yandex,
                                              },
                                              {
                                                  label: "InDrive",
                                                  value: ExternalDeliveryServices.InDrive,
                                              }
                                          ]}
                                          onChange={(value) => setFieldValue('deliveryService', value)}
                                          isClearable
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className="justify-content-center">
                                <Col md={'auto'}>
                                    <Button
                                        type={'submit'}
                                        color={'primary'}
                                        disabled={!dirty}
                                    >
                                        Применить
                                    </Button>
                                </Col>
                                <Col md={'auto'}>
                                    <Button
                                        onClick={resetFormHandler}
                                        disabled={isReset}
                                        color={'light'}
                                    >
                                        Сбросить
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
}

export default ArchiveOrderFilter;
