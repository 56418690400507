import axios, {AxiosRequestConfig} from 'axios';


type OptionsType = Exclude<AxiosRequestConfig, 'url, headers'>;

export const baseURL = process.env.NODE_ENV === 'production' && !process.env.DEV ? 'https://api-courier.emenu.delivery': 'http://api.courier.test';
export const sseURL = process.env.NODE_ENV === 'production' && !process.env.DEV ? 'https://sse-courier.emenu.delivery': 'http://api.courier.test';

export class Api {

    call(url: string, options: OptionsType) {

        const token = localStorage.getItem('auth');

        let authorization = {};

        if (!!localStorage.getItem('auth')) {
            authorization = {'Authorization': `Bearer ${token}`}
        }

        /**
         * @Todo параметры header'a
         * */

        return axios({
            baseURL,
            url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...authorization
            },
            ...options
        })
    };
}

interface IPagination {
    total:number
    by:number
    page:number
}

export class Pagination implements IPagination{
    total: number;
    by: number;
    page: number;

    constructor(headers:{[key:string]:string}) {
        this.total = +headers['x-pagination-total-count'] || 1;
        this.by = +headers['x-pagination-per-page'] || 1;
        this.page = +headers['x-pagination-page-count'] || 1;
    }

    getData() {
        return{
            total: this.total,
            by: this.by,
            page: this.page
        }
    }
}
