import {createSelector} from "reselect";
import queryString from 'query-string';
import _ from "lodash";


const ordersUrlSelector = (props: any) => {
    return props.location.search
};


export const ordersFilterReSelector = createSelector(
    [ordersUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(getParams, ['id', 'externalId', 'cityId', 'pointId', 'companyId', 'status', 'paymentTypeId', 'companyName']);

        const id = params.id ? +params.id: undefined;
        const externalId = params.externalId;
        const cityId = params.cityId ? +params.cityId : undefined;
        const pointId = params.pointId ? +params.pointId : undefined;
        const companyId = params.companyId ? +params.companyId : undefined;
        const status = params.status ? +params.status : undefined;
        const paymentTypeId = params.paymentTypeId ? +params.paymentTypeId : undefined;
        const companyName = params.companyName ? params.companyName : undefined;

        const filter = _.pickBy(
          {id, externalId, cityId, pointId, companyId, status, paymentTypeId, companyName},
          function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);


export const archiveOrdersFilterReSelector = createSelector(
    [ordersUrlSelector],
    (queryParams) => {
        const getParams = queryString.parse(queryParams);
        const params = _.pick(
            getParams,
            [
                'id',
                'externalId',
                'cityId', 'pointId',
                'companyId',
                'status',
                'courierId' ,
                'dateStart',
                'dateEnd',
                'paymentTypeId',
                'companyName',
                'hasReturnCheck',
                'isExternalCourier',
                'deliveryService',
            ]);

        const id = params.id ? +params.id: undefined;
        const externalId = params.externalId;
        const cityId = params.cityId ? +params.cityId : undefined;
        const pointId = params.pointId ? +params.pointId : undefined;
        const companyId = params.companyId ? +params.companyId : undefined;
        const status = params.status ? +params.status : undefined;
        const courierId = params.courierId ? +params.courierId : undefined;
        const paymentTypeId = params.paymentTypeId ? +params.paymentTypeId : undefined;
        const companyName = params.companyName ? params.companyName : undefined;
        const hasReturnCheck = params.hasReturnCheck ? (params.hasReturnCheck === 'true') : undefined;
        const isExternalCourier = params.isExternalCourier ? (params.isExternalCourier === 'true') : undefined;
        const deliveryService = params.deliveryService ? params.deliveryService : undefined;
        
        const dateStart = params.dateStart;
        const dateEnd = params.dateEnd;

        const filter = _.pickBy(
          {id, externalId, cityId, pointId, companyId, status, courierId, dateStart, dateEnd, paymentTypeId, companyName, hasReturnCheck, isExternalCourier, deliveryService},
          function(value) {
            return !(value === undefined || value === null || value === '');
        });

        return !_.isEmpty(filter) ? filter : undefined
    }
);
